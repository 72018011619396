import { AcpApptentivePlugin } from 'components/apptentive-domain';
import {
  AcpOrderCardModel,
  CardOwner,
  CardOwnerIdRequest,
  CardResponse,
  CardShippingMethod
} from 'components/card-domain';

import { AcpReportCardLostStolenModel } from './acp-report-card-lost-stolen-model';
import acpReportCardConfirm from './templates/acp-report-card-confirm.html';

export class AcpReportCardConfirmComponentCtrl
  implements nsUtils.NsComponentController {
  public shippingMethodFee: number;
  public shippingMethod: CardShippingMethod;
  public flowTitle;
  public card: CardResponse & { card_number: string };
  public isLoading: boolean = false;
  private cardId;
  private cardLostStolen;
  private isReplacementCardOrder: boolean;

  constructor(
    private acpCoreDispatcher,
    private $mdBottomSheet,
    private $mdDialog,
    private acpCardModel,
    private acpOrderCardModel: AcpOrderCardModel,
    private ACP_CARD_CONSTANTS,
    private acpReportCardLostStolenModel: AcpReportCardLostStolenModel,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';
  }

  public $onInit() {
    this.initializeCardData();
  }

  public async reportConfirm() {
    this.isLoading = true;
    this.shippingMethod = this.getShippingDetails();

    try {
      await this.acpReportCardLostStolenModel.markCardLostStolen({
        id: this.cardId,
        markLostStolen: this.cardLostStolen
      });

      try {
        this.acpApptentivePlugin.sendEvent('Report_card_success');
      } catch (error) {
        this.acpApptentivePlugin.errorLog(
          'AcpReportCardConfirmComponentCtrl',
          error
        );
      }
    } catch (er) {
      this.isLoading = false;
      this.acpCoreDispatcher.card.carkMarkStolenFail.emit(er);
      this.reportCancel();
      throw er;
    }

    if (this.isReplacementCardOrder) {
      await this.placeCardOrder(this.shippingMethod);
    }
    this.acpCardModel.resetCards();
    this.popupHide();
  }

  public reportCancel(): void {
    this.$mdBottomSheet.cancel();
    this.$mdDialog.cancel();
  }

  public popupHide(): void {
    this.$mdBottomSheet.hide();
    this.$mdDialog.hide();
  }

  public isExpediteShipping(): boolean {
    return this.shippingMethodFee > this.ACP_CARD_CONSTANTS.FEE_AMOUNT_ZERO;
  }

  private async placeCardOrder(shippingMethod: CardShippingMethod) {
    try {
      this.card = await this.acpCardModel.getCard(this.cardId);
      await this.updateShippingAddress({ id: this.card.card_owner_id });
      await this.acpOrderCardModel.placeOrder(
        { id: this.card.card_owner_id },
        { issuer_number: this.card.issuer_number },
        shippingMethod,
        this.ACP_CARD_CONSTANTS.ORDER_REASON.REPLACEMENT_CARD
      );
    } catch (ex) {
      this.isLoading = false;
      this.acpCoreDispatcher.card.cardOrderFail.emit();
    }
  }
  private getShippingDetails(): CardShippingMethod {
    const shipToAddress = this.acpOrderCardModel.getShippingAddress();
    return shipToAddress.shippingMethod;
  }

  private initializeCardData() {
    this.acpCardModel.getCard(this.cardId).then((card) => {
      this.card = card;
      this.card.card_number = this.getObscuredPan(
        `XXXX-XXXX-XXXX-${this.card.pan_last_4}`
      );
    });
  }

  private getObscuredPan(pan: string): string {
    return pan.replace(/XXXX-/g, '&#8226;&#8226;&#8226;&#8226;&nbsp;');
  }

  private async updateShippingAddress(
    owner: CardOwner | CardOwnerIdRequest
  ): Promise<void> {
    try {
      const shipToAddress = this.acpOrderCardModel.getShippingAddress();
      if (shipToAddress && shipToAddress.shippingAddress) {
        await this.acpOrderCardModel.updateShippingAddress(
          owner,
          shipToAddress.shippingAddress
        );
      }
    } catch (ex) {
      throw ex;
    }
  }
}

export const acpReportCardConfirmComponent: ng.IComponentOptions = {
  bindings: {
    cardId: '@',
    cardLostStolen: '@',
    shippingMethodFee: '=?',
    isReplacementCardOrder: '=?'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpReportCardConfirmComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpReportCardConfirm
};
