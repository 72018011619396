import ng from 'angular';
import { acpComponentApptentiveDomain } from 'components/apptentive-domain';
import acpCardDomainModule from 'components/card-domain';
import acpCore from 'core';
import acpUnsafeHtmlFilter from 'shared/angularjs/filters/acp-unsafe-html';
import { acpReportCardComponent } from './acp-report-card-component';
import { acpReportCardConfirmComponent } from './acp-report-card-confirm-component';
import { acpReportCardConfirmedComponent } from './acp-report-card-confirmed-component';
import { AcpReportCardLostStolenClient } from './acp-report-card-lost-stolen-client';
import { AcpReportCardLostStolenModel } from './acp-report-card-lost-stolen-model';
import { acpIllustrationModule } from 'components/illustration';
import acpReportCardContent from './report-card-content.yml';
import './styles/${theme}/core.scss';


/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.report-card-lost-stolen
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-select-card-owner></acp-select-card-owne>
 * ```
 *
 * ```js
 * import {acpOrderCardModule} from 'components/acpCardLanding/order-card';
 *
 * angular.module('acp.component.card-landing.example', [
 *     acpOrderCardModule.name
 * ]);
 * ```
 */

export const acpReportCardLostStolenModule = ng
  .module('acp.component.report-card-lost-stolen', [
    acpCore.name,
    acpUnsafeHtmlFilter.name,
    acpIllustrationModule.name,
    acpCardDomainModule.name,
    'netspend.legos.progressbar',
    acpComponentApptentiveDomain.name
  ])
  .component('acpReportCard', acpReportCardComponent)
  .component('acpReportCardConfirm', acpReportCardConfirmComponent)
  .component('acpReportCardConfirmed', acpReportCardConfirmedComponent)
  .service('acpReportCardLostStolenModel', AcpReportCardLostStolenModel)
  .service('acpReportCardLostStolenClient', AcpReportCardLostStolenClient)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put('components/report-card', acpReportCardContent);
  });

export { AcpReportCardLostStolenModel };

// @bazel-dep: components/order-card
