import { CardResponse } from 'components/card-domain';
import { CardLostStolenRequest } from './types/acp-card-lost-stolen-request';

export class AcpReportCardLostStolenClient {
  public getCardDetails = this.webapiResource<string, CardResponse>({
    method: 'GET',
    path: '/v1/cards/:id'
  });

  public markCardLostStolen = this.webapiResource<CardLostStolenRequest, void>({
    method: 'POST',
    path: '/v1/cards/:id/:markLostStolen'
  });

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
