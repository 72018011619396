import acpReportCardConfirmed from './templates/acp-report-card-confirmed.html';

export class AcpReportCardConfirmedComponentCtrl
  implements nsUtils.NsComponentController {
  public orderCardFail: boolean;
  public flowTitle;
  public isReplacementCardOrder: boolean;

  constructor(private nsInPageFlow) {
    'ngInject';
  }

  public returnToAccounts(): void {
    this.nsInPageFlow.close();
  }
}

export const acpReportCardConfirmedComponent: ng.IComponentOptions = {
  bindings: {
    orderCardFail: '=?',
    isReplacementCardOrder: '=?'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpReportCardConfirmedComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpReportCardConfirmed
};
