import ng from 'angular';
import {
  AcpOrderCardModel,
  Address,
  CardOwner,
  CardShipping,
  CardShippingMethod
} from 'components/card-domain';

import { AcpMedia } from 'core';
import acpReortCardLandingTemplate from './templates/acp-report-card.html';

export class AcpReportCardComponentCtrl
  implements nsUtils.NsComponentController {
  public isCardControlEnabled: boolean;
  public orderFail: boolean;
  public reportLostCardForm: ng.IFormController;
  public flowTitle;
  public cardId: string;
  public cardOwner: CardOwner;
  public markLostStolen: string = '';
  public $scope: ng.IScope;
  public shippingAddress: Address;
  public addressType: string;
  public shippingData: CardShipping;
  public shouldAskSecurityCheck: boolean;
  public secutityQuestionSuccess: boolean = false;
  public isMarkStolen: boolean = true;
  public shippingMethodFee: number;
  public shippingMethod: CardShippingMethod;
  public card;
  public permission: {};
  public serverErrors: string[] = [];
  public relevantErrors: string[] = [
    'card.marked_lost',
    'card.marked_stolen',
    'card.expired'
  ];
  public selectedCard: any;
  public isReplacementCardAvailable: boolean;
  public canOrderCard: boolean = false;
  private issuerNumber: string;

  constructor(
    nsComponentDecorator,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService,
    private acpMedia: AcpMedia,
    private nsInPageFlow: any,
    private acpCoreDispatcher: any,
    private acpAuthModel: any,
    private acpCardModel,
    private acpOrderCardModel: AcpOrderCardModel,
    private nsUtil: nsUtils.NsUtilService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private ACP_CARD_CONSTANTS: any
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
    this.shippingMethod = new CardShippingMethod();
    this.shippingAddress = new Address();
  }

  public $tiePermissions?(property: string, permissions: string[]);

  public async $onInit(): Promise<void> {
    // Perform initialization here
    this.securityCheck();
    this.initialization();
    this.canOrderCard = await this.OrderCardAvailable();
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  public reportContinue() {
    this.shippingData.shippingAddress = null;

    if (this.shippingAddress) {
      this.shippingData.shippingAddress = this.nsUtil.assign(
        new Address(),
        this.shippingAddress
      );
    }

    this.shippingData.shippingMethod = this.nsUtil.assign(
      new CardShippingMethod(),
      this.shippingMethod
    );

    this.acpOrderCardModel.setShippingAddress(this.shippingData);
    this.showConfirmationDialog();
  }

  public cancel(): void {
    this.nsInPageFlow.close();
  }

  public reportCardLostStolenValid(): boolean {
    const report = !this.markLostStolen;
    if (
      this.addressType !==
      this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.NEW_ADDRESS
    ) {
      return report;
    }
    return !(this.shippingAddress.line1 && this.shippingAddress.zip && !report);
  }

  public async goToLock(): Promise<void> {
    await this.nsInPageFlow.pop();
    this.acpCoreDispatcher.card.cardLock.emit();
  }

  private async OrderCardAvailable(): Promise<boolean> {
    const permissions = await this.nsPermissions.requestPermissions([
      'isCardControlEnabled',
      'isReplacementCardAvailable'
    ]);
    this.updatePermissions(permissions);
    if (this.isReplacementCardAvailable) {
      const isCustom = await this.isCustomCard(this.issuerNumber);
      return !isCustom;
    }
    return this.isReplacementCardAvailable;
  }
  private modelSuccess(): void {
    this.nsInPageFlow
      .push({
        template: `<acp-report-card-confirmed order-card-fail=${
          this.orderFail
        } is-replacement-card-order=${
          this.canOrderCard
        } ></acp-report-card-confirmed>`,
        suppressBackButton: true
      })
      .catch(() => {
        return void 0;
      });
  }

  private showConfirmationDialog(): void {
    let template = `<acp-report-card-confirm card-id=${
      this.cardId
    } card-lost-stolen=${this.markLostStolen} shipping-method-fee=${
      this.shippingMethodFee
    } is-replacement-card-order=${
      this.canOrderCard
    }></acp-report-card-confirm>`;

    if (this.acpMedia('mobile')) {
      template = `<md-bottom-sheet>${template}</md-bottom-sheet>`;
      this.$mdBottomSheet
        .show({
          template
        })
        .then(this.modelSuccess.bind(this));
      return;
    }

    this.$mdDialog
      .show({
        template,
        clickOutsideToClose: true
      })
      .then(this.modelSuccess.bind(this));
  }

  private async isCustomCard(issuerNumber: string): Promise<boolean> {
    let customCards = await this.acpOrderCardModel.fetchCardBrands({
      type: this.ACP_CARD_CONSTANTS.CUSTOM
    });
    customCards = customCards.filter(
      (card) => card.issuer_number === issuerNumber
    );
    return customCards.length > 0 ? true : false;
  }

  private async updateShippingAddress() {
    try {
      this.card = await this.acpCardModel.getCard(this.cardId);
      await this.acpOrderCardModel.setCardOwnerModel({
        id: this.card.card_owner_id
      });
      this.cardOwner = this.acpOrderCardModel.getCardOwnerModel();
      this.selectedCard = { issuer_number: this.card.issuer_number };
    } catch (ex) {
      throw ex;
    }
  }

  private securityCheck(): void {
    this.acpAuthModel.getInfo().then((securityDataLocker) => {
      this.shouldAskSecurityCheck = !(
        securityDataLocker.questions || securityDataLocker.ooba
      );
      this.secutityQuestionSuccess = true;
      this.updateShippingAddress();
    });
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isCardControlEnabled = permissions && permissions.isCardControlEnabled;
    this.isReplacementCardAvailable =
      permissions && permissions.isReplacementCardAvailable;
  }

  private signalListners(): void {
    this.$onValue(
      this.acpCoreDispatcher.authenticate.question.answer.success,
      () => {
        this.shouldAskSecurityCheck = false;
      }
    );
    this.$onValue(this.acpCoreDispatcher.card.cardOrderFail, () => {
      this.orderFail = true;
    });
    this.$onValue(this.acpCoreDispatcher.card.carkMarkStolenFail, (er) => {
      this.cardLostStolenError(er);
    });
  }

  private cardLostStolenError(response): void {
    this.serverErrors = ['server.unknown'];
    if (
      response &&
      response._server_errors &&
      response._server_errors.length > 0 &&
      this.relevantErrors.indexOf(response._server_errors[0]) !== -1
    ) {
      this.serverErrors = response._server_errors;
    }
  }

  private initialization(): void {
    this.signalListners();
    this.shippingData = {
      shippingAddress: new Address(),
      shippingMethod: new CardShippingMethod()
    };
  }
}

export const acpReportCardComponent: ng.IComponentOptions = {
  bindings: {
    cardId: '@',
    issuerNumber: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpReportCardComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpReortCardLandingTemplate
};
